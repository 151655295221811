<template>
    <div>
        <h2>{{ $t("tiers.menu_chevaux") }}</h2>
        <div class="box">
            <div class="row">

                <div class="col-6">
                    <div class="mb-2">
                        <label class="mb-1" for="tiers_id">{{ $t('tiers.tiers') }} *</label>
                        <e-select
                            v-model="tier_externe"
                            track-by="tiers_id"
                            label="tiers_rs"
                            :placeholder="labelTitleTiers"
                            :selectedLabel="selectedLabel"
                            :options="tiers_externe"
                            :searchable="true"
                            :allow-empty="false"
                            :loading="isLoadingTiers"
                            :show-labels="false"
                        >
                            <template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }} {{ option.tiers_postalcode }}</template>
                            <template slot="option" slot-scope="{ option }">{{ option.tiers_rs }} {{ option.tiers_postalcode }}</template>
                            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                        </e-select>
                    </div>
                </div>
                <div class="col">
                    <label for="date">{{ $t('global.date_debut') }}</label>
                    <e-datepicker id="date" v-model="date_debut" :class="{ 'is-invalid': errors && errors.indexOf('Date error') > -1 }"></e-datepicker>
                </div>

                <div class="col">
                    <label for="date">{{ $t('global.date_fin') }}</label>
                    <e-datepicker id="date_fin" v-model="date_fin" :class="{ 'is-invalid': errors && errors.indexOf('Date error') > -1 }"></e-datepicker>
                </div>

                <div class="col">
                    <label>&nbsp;</label>
                    <button class="btn btn-primary btn-block" @click.prevent="loadHorseComponent()">{{ $t('global.rechercher') }}</button>
                </div>
            </div>
        </div>
        <div class="box">

            <CustomTable
                ref="table"
                id_table="tiers_horse_exterieur"
                :items="horses"
                :busy.sync="table_busy"
                primaryKey="tiershorse_id"
                :hrefsRoutes="config_table_hrefs"
                >
            </CustomTable>
        </div>  

    </div>
</template>

<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js";
    import TiersMixin from "@/mixins/Tiers.js"
    import MouvementsMixin from "@/mixins/Mouvements.js"
    import CustomTable from "GroomyRoot/components/Table/CustomTable"

    export default {
        name: "HorseExterieur",
        mixins: [Navigation, TiersMixin, MouvementsMixin],
        props: ['tiers'],
        data () {
            return {
                labelTitleTiers: this.getTrad("tiers.rechercher"),
				selectedLabel: this.getTrad("global.selected_label"),
                isLoadingTiers: false,
                tiers_externe: [],
                tier_externe: null,
                errors: [],
                horses: [],
                date_debut: new Date(new Date().setDate(new Date().getDate() - 30)),
				date_fin: new Date(),
                disableButton: false,
                processing: false,
                tiers_horse: null,
                table_busy: false,
                config_table_hrefs: {
                    'horse.horse_nom': {
                        routeName: 'horseFiche',
                        params: {
                            horse_id: 'horse.horse_id'
                        }
                    }
                },
                events_tab: {}
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                await this.loadAllTiers()
            },
            async loadHorseComponent() {

                this.table_busy = true
                if (!this.tier_externe) {
                    this.failureToast("toast.tiers_not_given")
                    this.table_busy = false
                    return
                }

                const tiers = await this.loadHorsePart(this.tiers.tiers_id)

                let format_ids = []
                let temp_horse = []
                for(const horse of tiers.tiers_horse){
                    if (!format_ids.includes(horse.horse.horse_id)){
                        format_ids.push(horse.horse.horse_id)
                        temp_horse.push(horse)
                    }
                }

                let horses_ids = temp_horse.map(horse => horse.horse.horse_id)

                const presence_day_by_horse = await this.getPresenceDaysByTier(horses_ids, this.tier_externe.tiers_id, this.date_debut, this.date_fin)

                this.horses = temp_horse.filter(horse => horse.horse.horse_id in presence_day_by_horse)
                this.horses.map(horse => horse.nb_jours = presence_day_by_horse[horse.horse.horse_id])

                this.table_busy = false
            },
            async loadAllTiers(){
				this.isLoadingTiers = true
				this.tiers_externe = await this.loadTiers()
				this.isLoadingTiers = false
			},
        },
        components: {
            CustomTable,
        }
    }
</script>
